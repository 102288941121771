export function mouseOverEffect() {
  let e = document.getElementById("cursor2");
  let i = document.getElementById("cursor3");

  e.classList.add("hover");
  i.classList.add("hover");
}

export function mouseOutEffect() {
  let e = document.getElementById("cursor2");
  let i = document.getElementById("cursor3");

  e.classList.remove("hover");
  i.classList.remove("hover");
}
