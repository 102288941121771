import React, { useState } from "react";
import "../App.css";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  function check(e) {
    if (e.target.value) {
      e.target.classList.add("not-empty");
    } else {
      e.target.classList.add("not-empty");
    }
  }

  return (
    <section id="contact">
      <div id="get-in-touch">
        <h2 className="section-title">Contact</h2>
        <form
          className="contact-form row"
          name="contact"
          method="POST"
          data-netlify="true"
          action="https://formsubmit.co/2103cd8425e2b6b435b9a13234b46f4a"
        >
          <div className="form-field col x-50">
            <input
              id="name"
              name="name"
              className="input-text js-input"
              type="text"
              required
              onKeyUp={(e) => check(e)}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <label className="label" htmlFor="name">
              Name
            </label>
          </div>
          <div className="form-field col x-50">
            <input
              id="email"
              name="email"
              className="input-text js-input"
              type="email"
              required
              onKeyUp={(e) => check(e)}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label className="label" htmlFor="email">
              E-mail
            </label>
          </div>
          <div className="form-field col x-100">
            <input
              id="message"
              name="Message"
              className="input-text js-input"
              type="text"
              required
              onKeyUp={(e) => check(e)}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <label className="label" htmlFor="message">
              Message
            </label>
          </div>
          <div className="form-field col x-100 align-center">
            <button className="submit-btn" type="submit">
              {"SUBMIT"}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Contact;
