import React, { Suspense } from "react";
import Navigation from "../components/Navigation";
import "../App.css";
import Skills from "./Skills";
import Footer from "./Footer";
import Contact from "./Contact";
import Projects from "./Projects";
import Scene from "../assets/scene.spline";
import Cursor from "../components/Cursor";
import { mouseOverEffect, mouseOutEffect } from "../utils/CursorEffects";
import Spline from "@splinetool/react-spline";

const Home = () => {
  return (
    <>
      <section id="hero">
        <Navigation />
        {/* <Fade direction="left"> */}
        <div className="container">
          <h1
            className="hero-title"
            onMouseOver={mouseOverEffect}
            onMouseOut={mouseOutEffect}
          >
            Hi, I'm <span className="text-color-main">Swastik Patil.</span>
            <br />
            <span className="role">I'm a Software Developer.</span>
          </h1>
          <p className="hero-cta">
            <span className="cta-btn cta-btn--hero">
              <a href="/#skills" aria-label="Go to Skills Section">
                Skills
              </a>
            </span>
            <span className="cta-btn cta-btn--hero">
              <a href="/#projects" aria-label="Go to Projects Section">
                Projects
              </a>
            </span>
            <span className="cta-btn cta-btn--hero">
              <a href="/#contact" aria-label="Go to Contact Section">
                Contact
              </a>
            </span>
          </p>
        </div>
        {/* {
          // Check device width and render 3D view only on desktop
          window.innerWidth > 768 && (
            <Suspense
              fallback={
                <div className="loader">
                  <div className="loader-inner"></div>
                </div>
              }
            >
              <Spline
                scene={Scene}
                className="threeDview"
                style={{ width: "50%" }}
              />
            </Suspense>
          )
        } */}
      </section>
      <Skills />
      <Projects />
      <Contact />
      <Footer />
      <Cursor />
    </>
  );
};

export default Home;
