import React from "react";

import Section1 from "../assets/Section 1.png";
import GppenPortal1 from "../assets/GppenPortal1.png";
import GppenPortal2 from "../assets/GppenPortal2.png";
import GppenPortal3 from "../assets/GppenPortal3.png";
import GppenPortal4 from "../assets/GppenPortal4.png";
import GppenPortal5 from "../assets/GppenPortal5.png";

import AICTE1 from "../assets/AICTE.avif";
import AICTE2 from "../assets/AICTE2.avif";
import AICTE3 from "../assets/AICTE3.avif";
import AICTE4 from "../assets/AICTE4.avif";

import Ecommerce1 from "../assets/Ecommerce1.png";
import Ecommerce2 from "../assets/Ecommerce2.png";
import Ecommerce3 from "../assets/Ecommerce3.png";
import Ecommerce4 from "../assets/Ecommerce4.png";
import Ecommerce5 from "../assets/Ecommerce5.png";
import Ecommerce6 from "../assets/Ecommerce6.png";

import Algo1 from "../assets/algo1.png";
import Algo2 from "../assets/algo2.png";
import Algo3 from "../assets/algo3.png";

import ArrowLeft from "../assets/leftarrow.png";
import ArrowRight from "../assets/rightarrow.png";
import { Fade } from "react-awesome-reveal";

function Projects() {
  function slideLeft(e) {
    let list = e.target.parentElement.nextElementSibling.children;
    let index = 0;
    for (let i = 0; i < list.length; i++) {
      if (list[i].classList.contains("active")) {
        index = i;
      }
    }
    list[index].classList.remove("active");
    if (index === 0) {
      index = list.length;
    }
    list[index - 1].classList.add("active");

    let imgs =
      e.target.parentElement.parentElement.previousElementSibling.children[0]
        .children;
    index = 0;
    for (let i = 0; i < imgs.length; i++) {
      if (imgs[i].classList.contains("active")) {
        index = i;
      }
    }
    imgs[index].classList.remove("active");
    if (index === 0) {
      index = imgs.length;
    }
    imgs[index - 1].classList.add("active");
  }

  function slideRight(e) {
    let list = e.target.parentElement.previousElementSibling.children;
    let index = 0;
    for (let i = 0; i < list.length; i++) {
      if (list[i].classList.contains("active")) {
        index = i;
        break;
      }
    }
    list[index].classList.remove("active");
    if (index === list.length - 1) {
      index = -1;
    }
    list[index + 1].classList.add("active");

    let imgs =
      e.target.parentElement.parentElement.previousElementSibling.children[0]
        .children;
    index = 0;
    for (let i = 0; i < imgs.length; i++) {
      if (imgs[i].classList.contains("active")) {
        index = i;
        break;
      }
    }
    imgs[index].classList.remove("active");
    if (index === imgs.length - 1) {
      index = -1;
    }
    imgs[index + 1].classList.add("active");
  }

  return (
    <section id="projects">
      <div className="container">
        <div className="project-wrapper">
          <Fade direction="left">
            <h2 className="section-title">Projects</h2>
          </Fade>
          <div className="personal-projects">
            <Fade direction="left">
              <div className="title">
                <h3>Personal Projects </h3>
              </div>
            </Fade>
            <div className="project">
              <Fade direction="left">
                <div className="wrapper">
                  <div className="project-details">
                    <div className="project-wrapper__text">
                      <h3 className="project-wrapper__text-title">
                        E-Commerce
                      </h3>
                      <div>
                        <div className="tech-stack">
                          <div className="tech">React</div>
                          <div className="tech">Express</div>
                          <div className="tech">NodeJS</div>
                          <div className="tech">MongoDB</div>
                          <div className="tech">Stripe</div>
                        </div>
                        <p>
                          This is an e-commerce website which is the ultimate
                          destination for purchasing stunning Ganpati idols.
                          Powered by ReactJS, Express JS, and Node JS, This
                          platform offers a seamless shopping experience with a
                          user-friendly interface.
                          <br /> With MongoDB as the database, it ensure
                          efficient storage and retrieval of product
                          information. To provide a secure and convenient
                          payment process, I have integrated Stripe as our
                          payment gateway. Explore my website and bring home the
                          perfect Ganpati idol for your spiritual needs.
                        </p>
                      </div>
                      <div className="project-links">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn project-btn--hero"
                          href="https://shrivinayakartsstore.web.app/"
                        >
                          See Live
                        </a>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn project-btn--hero"
                          href="https://github.com/Swastik-Patil/ShreeVinayakArts"
                        >
                          Source Code
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
              <Fade direction="right">
                <div className="wrapper">
                  <div className="project-img-container">
                    <div className=" project-wrapper__image">
                      <div className="slideshow">
                        <img
                          src={Ecommerce1}
                          className="active"
                          alt="Ecommerce 1"
                        />
                        <img src={Ecommerce2} className="" alt="Ecommerce 2" />
                        <img src={Ecommerce3} className="" alt="Ecommerce 3" />
                        <img src={Ecommerce4} className="" alt="Ecommerce 4" />
                        <img src={Ecommerce5} className="" alt="Ecommerce 5" />
                        <img src={Ecommerce6} className="" alt="Ecommerce 6" />
                      </div>
                    </div>
                    <div className="slideBtn">
                      <div className="leftarrow">
                        <img
                          src={ArrowLeft}
                          alt="arrow"
                          onClick={(e) => slideLeft(e)}
                        />
                      </div>
                      <ul>
                        <li className="active">|</li>
                        <li className="">|</li>
                        <li className="">|</li>
                        <li className="">|</li>
                        <li className="">|</li>
                        <li className="">|</li>
                      </ul>
                      <div className="leftarrow">
                        <img
                          src={ArrowRight}
                          alt="arrow"
                          onClick={(e) => slideRight(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
            <div className="project">
              <Fade direction="left">
                <div className="wrapper">
                  <div className="project-details">
                    <div className="project-wrapper__text">
                      <h3 className="project-wrapper__text-title">
                        Algorithm Visualizer
                      </h3>
                      <div>
                        <div className="tech-stack">
                          <div className="tech">JavaScript</div>
                          <div className="tech">HTML</div>
                          <div className="tech">CSS</div>
                        </div>
                        <p>
                          It is a way of learning Algorithms through
                          visualization which help the students and teachers
                          also. Algorithm Visualizer allows the human to
                          visualize an algorithm and extend human intellect.
                          <br />
                          Threre are four algorithms : Depth First Search (DFS),
                          Restoring division, Non-Restoring division, and Booths
                          multiplication.
                        </p>
                      </div>
                      <div className="project-links">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn project-btn--hero"
                          href="https://algovisualizr.web.app/"
                        >
                          See Live
                        </a>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn project-btn--hero"
                          href="https://github.com/Swastik-Patil/AlgoVisualizr"
                        >
                          Source Code
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
              <Fade direction="right">
                <div className="wrapper">
                  <div className="project-img-container">
                    <div className="project-wrapper__image">
                      <div className="slideshow">
                        <img src={Algo1} className="active" alt="Algo1" />
                        <img src={Algo2} className="" alt="Algo2" />
                        <img src={Algo3} className="" alt="Algo3" />
                      </div>
                    </div>
                    <div className="slideBtn">
                      <div className="leftarrow">
                        <img
                          src={ArrowLeft}
                          alt="arrow"
                          onClick={(e) => slideLeft(e)}
                        />
                      </div>
                      <ul>
                        <li className="active">|</li>
                        <li>|</li>
                        <li>|</li>
                      </ul>
                      <div className="leftarrow">
                        <img
                          src={ArrowRight}
                          alt="arrow"
                          onClick={(e) => slideRight(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
          <div className="group-projects">
            <Fade direction="left">
              <div className="title">
                <h3>Group Projects </h3>
              </div>
            </Fade>
            <div className="project">
              <Fade direction="left">
                <div className="wrapper">
                  <div className="project-details">
                    <div className=" project-wrapper__text">
                      <h3 className="project-wrapper__text-title">
                        Goverment Polytecnic Pen, Portal
                      </h3>
                      <div>
                        <div className="tech-stack">
                          <div className="tech">React</div>
                          <div className="tech">Firebase</div>
                          <div className="tech">Bootstrap</div>
                        </div>
                        <p className="bold">
                          Collaborators:{" "}
                          <span className="italics">
                            Yash Dabhade, Swapnil Chavan
                          </span>
                        </p>
                        <p>
                          It is an online portal for students where they can
                          fill out a form online for getting a bonafide
                          Certificate from their college, where students don't
                          need to go to college for collecting their
                          certificates in person, Certificates will be generated
                          online.
                        </p>
                        <ul className="italics note">
                          <li style={{ listStyle: "none" }}>
                            Note: The app has an authentication so use the
                          </li>
                          <li>
                            <strong>Student Login</strong> username:
                            test@gmail.com // password: test123
                          </li>
                          <li>
                            <strong>HOD Login</strong> username:
                            hodcomputer@gmail.com // password: hodcomputer
                          </li>
                          <li style={{ listStyle: "none" }}>
                            Also, the source code is on one of our team member's
                            GitHub.
                          </li>
                          <li style={{ listStyle: "none" }}>
                            i.e., https://github.com/Yash-Dabhade/gppenPortal.
                          </li>
                        </ul>
                      </div>
                      <div className="project-links">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn project-btn--hero"
                          href="https://gov-poly-pen-portal.web.app/"
                        >
                          See Live
                        </a>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn project-btn--hero"
                          href="https://github.com/Yash-Dabhade/gppenPortal"
                        >
                          Source Code
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
              <Fade direction="right">
                <div className="wrapper">
                  <div className="project-img-container">
                    <div className=" project-wrapper__image">
                      <div className="slideshow">
                        <img src={Section1} className="active" alt="section1" />
                        <img
                          src={GppenPortal1}
                          className=""
                          alt="GppenPortal1"
                        />
                        <img
                          src={GppenPortal2}
                          className=""
                          alt="GppenPortal2"
                        />
                        <img
                          src={GppenPortal3}
                          className=""
                          alt="GppenPortal3"
                        />
                        <img
                          src={GppenPortal4}
                          className=""
                          alt="GppenPortal4"
                        />
                        <img
                          src={GppenPortal5}
                          className=""
                          alt="GppenPortal5"
                        />
                      </div>
                    </div>
                    <div className="slideBtn">
                      <div className="leftarrow">
                        <img
                          src={ArrowLeft}
                          alt="arrow"
                          onClick={(e) => slideLeft(e)}
                        />
                      </div>
                      <ul>
                        <li className="active">|</li>
                        <li>|</li>
                        <li>|</li>
                        <li>|</li>
                        <li>|</li>
                        <li>|</li>
                      </ul>
                      <div className="leftarrow">
                        <img
                          src={ArrowRight}
                          alt="arrow"
                          onClick={(e) => slideRight(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
            <div className="project">
              <Fade direction="left">
                <div className="wrapper">
                  <div className="project-details">
                    <div className=" project-wrapper__text">
                      <h3 className="project-wrapper__text-title">
                        AICTE Portal for Curriculum - SIH22
                      </h3>
                      <div>
                        <div className="tech-stack">
                          <div className="tech">React</div>
                          <div className="tech">Firebase</div>
                          <div className="tech">Tailwind</div>
                        </div>
                        <p className="bold">
                          Collaborators:
                          <span className="italics">
                            Yash, Swapnil, Sakshi, Arya, Harshal
                          </span>
                        </p>
                        <ul>
                          <li>
                            This project fills the gap between the technologies
                            used in the market and the subjects taught in the
                            college.
                          </li>
                          <li>
                            It has an algorithm that is capable of finding the
                            best fit for a particular subject of a given course
                            in a curriculum considering the new technology and
                            its prerequisites.
                          </li>
                        </ul>
                        <p className="italics note">
                          Note: The app has an authentication, Use the username:
                          admin@gmail.com and password: admin123 to explore the
                          app with cached dummy data. Also, the source code is
                          on one of our team member's GitHub. <br />
                          i.e.,
                          https://github.com/Yash-Dabhade/AICTE_PORTAL_SIH22.
                        </p>
                      </div>
                      <div className="project-links">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn project-btn--hero"
                          href="https://aicte-portal.web.app/"
                        >
                          See Live
                        </a>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn project-btn--hero"
                          href="https://github.com/Yash-Dabhade/AICTE_PORTAL_SIH22"
                        >
                          Source Code
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
              <Fade direction="right">
                <div className="wrapper">
                  <div className="project-img-container">
                    <div className=" project-wrapper__image">
                      <div className="slideshow">
                        <img src={AICTE1} className="active" alt="AICTE1" />
                        <img src={AICTE2} className="" alt="AICTE2" />
                        <img src={AICTE3} className="" alt="AICTE3" />
                        <img src={AICTE4} className="" alt="AICTE4" />
                      </div>
                    </div>
                    <div className="slideBtn">
                      <div className="leftarrow">
                        <img
                          src={ArrowLeft}
                          alt="arrow"
                          onClick={(e) => slideLeft(e)}
                        />
                      </div>
                      <ul>
                        <li className="active">|</li>
                        <li>|</li>
                        <li>|</li>
                        <li>|</li>
                      </ul>
                      <div className="leftarrow">
                        <img
                          src={ArrowRight}
                          alt="arrow"
                          onClick={(e) => slideRight(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Projects;
