import React from "react";
import { useState, useEffect } from "react";

const Cursor = () => {
  const [followerX, setFollowerX] = useState(0);
  const [followerY, setFollowerY] = useState(0);

  useEffect(() => {
    const handleMouseMove = (event) => {
      setFollowerX(event.clientX);
      setFollowerY(event.clientY);
    };
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <>
      <div
        className="cursor"
        id="cursor"
        style={{ top: `${followerY}px`, left: `${followerX}px` }}
      ></div>
      <div
        className="cursor2"
        id="cursor2"
        style={{ top: `${followerY}px`, left: `${followerX}px` }}
      ></div>
      <div
        className="cursor3"
        id="cursor3"
        style={{ top: `${followerY}px`, left: `${followerX}px` }}
      ></div>
    </>
  );
};

export default Cursor;
